import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ReactComponent as Back } from '../assets/back-button.svg';
import Status from '../components/Status';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchHSMOrderData,
  fetchOperations,
  fetchSpinoffData,
} from '../store/actions/appActions';
import Spinner from './Spinner';
import NoSubs from './NoSubs';
import { portalToolkitSKU, schemaReplicatorSKU } from '../utils/productSKU';
const pageUrl = new URL(window.location.href);
const baseUrl = pageUrl.origin;

const getFilteredApps = (
  sku,
  hsmOrders,
  portalToolkitData,
  schemaReplicatorData
) => {
  if (portalToolkitData?.operations && sku === portalToolkitSKU) {
    return portalToolkitData.operations;
  }

  if (schemaReplicatorData?.operations && sku === schemaReplicatorSKU) {
    return schemaReplicatorData.operations;
  }

  if (!hsmOrders || !hsmOrders.orders) {
    return [];
  }

  switch (sku) {
    case 'HSM':
      return hsmOrders.orders.filter((user) =>
        ['HSMLITE', 'HSMPRO', 'HSMENT'].includes(user.productCode)
      );
    // case 'HSMSCH':
    //   return hsmOrders.orders.filter((user) => user.productCode === 'HSMSCH');
    default:
      return [];
  }
};

const AppInfoLayout = ({ navigate, sku }) => {
  const { hsmOrders, loading, portalToolkitData, schemaReplicatorData, error } =
    useSelector((state) => state.app);
  const [spinoffLoading, setSpinoffLoading] = useState(false);
  const fetchedSKUs = useSelector((state) => state.sku.fetchedSKUs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!fetchedSKUs.includes(sku) && !loading && !error.includes(sku)) {
      if (sku === portalToolkitSKU || sku === schemaReplicatorSKU) {
        if (
          (sku === portalToolkitSKU && !portalToolkitData) ||
          (sku === schemaReplicatorSKU && !schemaReplicatorData)
        ) {
          dispatch(fetchOperations(sku));
        }
      } else {
        dispatch(fetchHSMOrderData(sku));
      }
    }
  }, [
    sku,
    fetchedSKUs,
    loading,
    dispatch,
    portalToolkitData,
    schemaReplicatorData,
    error,
  ]);

  const handleCreateNewOperation = async (sku) => {
    let skuData;
    switch (sku) {
      case portalToolkitSKU:
        skuData = portalToolkitData;
        break;
      case schemaReplicatorSKU:
        skuData = schemaReplicatorData;
        break;
      default:
        break;
    }
    if (skuData?.subscription) {
      setSpinoffLoading(true);
      try {
        const spinoffData = await dispatch(
          fetchSpinoffData(skuData.subscription.id)
        );
        if (spinoffData?.reconfigurationUrl) {
          window.location.href =
            spinoffData.reconfigurationUrl +
            `?redirectUrl=${baseUrl}/account/my-app-information-item/app/${spinoffData.id}`;
        }
      } catch (error) {
        console.error('Failed to fetch spinoff data:', error);
      } finally {
        setSpinoffLoading(false);
      }
    }
  };

  console.log(error, 'error');

  const handleApp = (userId, sku) => {
    navigate(`/account/my-app-information-item/app/${userId}`);
  };

  const appData = getFilteredApps(
    sku,
    hsmOrders,
    portalToolkitData,
    schemaReplicatorData
  );

  if (loading) {
    return <Spinner label="Loading..." />;
  }

  if (error.includes(sku)) {
    return <p>Something went wrong. Please try again later.</p>;
  }

  if (
    appData.length === 0 &&
    !sku.includes(portalToolkitSKU) &&
    !sku.includes(schemaReplicatorSKU)
  ) {
    return (
      <div className="app-container">
        <NoSubs />
      </div>
    );
  }

  if (
    (sku === portalToolkitSKU && !portalToolkitData?.subscription) ||
    (sku === schemaReplicatorSKU && !schemaReplicatorData?.subscription)
  ) {
    return (
      <div className="app-container">
        <NoSubs />
      </div>
    );
  }

  return (
    <div className="app-container">
      <Back
        style={{
          marginBottom: '1em',
          cursor: 'pointer',
        }}
        onClick={() => navigate('/account/my-app-information-item/')}
      />
      <div className="mainheading">
        <div className="headingtitle">My App Information</div>
        <div className="subtitle">
          Select{' '}
          {sku === portalToolkitSKU ? (
            'a migration below to view its status'
          ) : (
            <>
              {sku === schemaReplicatorSKU
                ? 'a replication below to view its status'
                : 'a migration below to view its status'}
            </>
          )}
        </div>
        {((sku === portalToolkitSKU && portalToolkitData?.subscription) ||
          (sku === schemaReplicatorSKU &&
            schemaReplicatorData?.subscription)) && (
          <div
            className="emailbtn"
            onClick={() => handleCreateNewOperation(sku)}
            style={{
              opacity: spinoffLoading ? 0.5 : 1,
              cursor: spinoffLoading ? 'not-allowed' : 'pointer',
            }}
          >
            {spinoffLoading
              ? 'Loading...'
              : `Create New ${
                  sku === portalToolkitSKU ? 'Migration' : 'Replication'
                }`}
          </div>
        )}
      </div>
      {appData.length > 0 ? (
        <>
          <div className="headers">
            <div style={{ textAlign: 'center' }}>
              {sku === portalToolkitSKU
                ? 'Migration Name'
                : sku === schemaReplicatorSKU
                ? 'Replication Name'
                : 'Version'}
            </div>
            <div>
              {sku === portalToolkitSKU
                ? 'Created Date'
                : sku === schemaReplicatorSKU
                ? 'Version'
                : 'Order Date'}
            </div>
            <div>Source Portal</div>
            <div>Destination Portal</div>
            <div>Status</div>
          </div>
          {appData.map((user, index) => (
            <div
              className="subs"
              key={index}
              onClick={() => handleApp(user.id, user.productCode)}
            >
              <div
                className="gridmain1"
                style={{
                  textAlign: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {sku === portalToolkitSKU || sku === schemaReplicatorSKU ? (
                  user.alias
                ) : (
                  <>
                    {user.productCode === 'HSMENT'
                      ? 'Enterprise'
                      : user.productCode === 'HSMPRO'
                      ? 'Pro'
                      : user.productCode === 'HSMLITE'
                      ? 'Lite'
                      : 'Schema Replicator'}
                  </>
                )}
              </div>
              <div className="gridmain2">
                {sku === portalToolkitSKU
                  ? moment(user.orderDate)
                      .tz('America/New_York')
                      .format('MM/DD/YY')
                  : sku === schemaReplicatorSKU
                  ? user.productName
                  : user.orderDate
                  ? moment(user.orderDate)
                      .tz('America/New_York')
                      .format('MM/DD/YY, h:mm:ss A')
                  : 'Not Available'}
              </div>
              <div className="gridmain3">
                {user.sourcePortalId ? user.sourcePortalId : ''}
              </div>
              <div className="gridmain4">
                {user.targetPortalId ? user.targetPortalId : ''}
              </div>

              <div className="gridmain5" style={{ fontStyle: 'italic' }}>
                <Status status={user.status} />
              </div>
            </div>
          ))}
        </>
      ) : (
        <p style={{ paddingLeft: '15px' }}>
          No {sku === schemaReplicatorSKU ? 'replications' : 'migrations'} found
        </p>
      )}
    </div>
  );
};

export default AppInfoLayout;
